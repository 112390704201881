var DV = "\u07b0\u0787\u07ac\u0783\u078c\u0794\u07aa\u07a8\u07ae\u0795\\\u07a6\u0790\u078b\u078a\u078e\u0780\u0796\u0786\u078d\u061b'\u0792\xd7\u0797\u0788\u0784\u0782\u0789\u060c./\u07a4\u07a2\u07ad\u079c\u0793\u07a0\u07ab\u07a9\u07af\xf7|\u07a7\u0781\u0791\ufdf2\u07a3\u0799\u079b\u079a\u0785:\"\u07a1\u0798\u079d\u07a5\u079e\u078f\u079f\u061f";
var EN = "qwertyuiop\\asdfghjkl;'zxcvbnm,./QWERTYUIOP|ASDFGHJKL:\"ZXCVBNM?";

function dv(en)
{
    let dv = '';

   if (en != undefined){
       en.split("").forEach(i => {
           idx = EN.indexOf(i);
           if (idx > -1){
               dv+=DV.substr(idx, 1);
           }
           else {
               dv+=i;
           }
       });
   }
    return dv;
}

document.addEventListener('input', (event) => {
    if (event.target.classList.contains('no-dv')) return;
    if (event.target.type === 'text' || event.target.type == 'textarea')
    {
        let input = event.target.value;
        event.target.value = dv(input);
    }
});
